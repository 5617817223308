
export const bwpThemes = {
  light: {
    dark: false,
    colors: {
      primary: '#B6B6B6',
      'on-primary': '#101213',
      secondary: '#858585',
      'on-secondary': '#fff',
      success: '#28C76F',
      'on-success': '#fff',
      info: '#3498FF',
      'on-info': '#fff',
      warning: '#FCD700',
      'on-warning': '#FFCE50',
      error: '#EB1C2F',
      'on-error': '#fff',
      background: '#fff',
      'on-background': '#101213',
      'on-surface': '#33303C',
      'grey-50': '#FAFAFA',
      'grey-100': '#F5F5F5',
      'grey-200': '#EEEEEE',
      'grey-300': '#E0E0E0',
      'grey-400': '#BDBDBD',
      'grey-500': '#9E9E9E',
      'grey-600': '#757575',
      'grey-650': '#858585',
      'grey-700': '#616161',
      'grey-800': '#424242',
      'grey-900': '#212121',
      'perfect-scrollbar-thumb': '#DBDADE',
      'sys-alert-low': '#EBECF0',
      'on-sys-alert-low': '#101213',
      'sys-alert-medium': '#DAE6F5',
      'on-sys-alert-medium': '#101213',
      'sys-alert-high': '#FFE5E0',
      'on-sys-alert-high': '#101213',
      'ds-card-res-usage': '#f4f4f4',
      'ds-pm-card-ulm': '#F8F0F1',
      'ds-pm-card-tuna' : '#edf1f6',
      'ds-spi-dm': '#fbfbfb',
      'btn-border': '#B9B9B9',
      'cards-border': '#B6B6B6',
      'layout-gridlines': '#B6B6B6',
      'nav-link-active': '#F1F1F1',
      'icons-color': '#101213',
      'table-header-bg': '#F1F1F1',
      'on-table-header-bg': '#101213',
      'table-header-border': '#B6B6B6',
      'main-primary': '#032140',
      'main-secondary': '#EB1C2F',
      'on-main-primary': '#fff',
      'on-main-secondary': '#fff',
      'textfield-bg': '#f1f1f1',
      'selects-bg': '#f1f1f1',
      'site-wp-card-bg': '#F1F1F1',
      'btn-text': '#101213',
      white: '#fff',
      'ob-skin': '#F9F9F9,'

    },
    variables: {
      'border-color': '#4B465C',
      'medium-emphasis-opacity': 0.68,

      // Shadows
      'shadow-key-umbra-opacity': 'rgba(var(--v-theme-on-surface), 0.03)',
      'shadow-key-penumbra-opacity': 'rgba(var(--v-theme-on-surface), 0.02)',
      'shadow-key-ambient-opacity': 'rgba(var(--v-theme-on-surface), 0.01)',
      'textfields-bg': 'rgba(var(--v-theme-textfield-bg), 0.4)',
    },
  },
  dark: {
    dark: true,
    colors: {
      primary: '#D9D9D9',
      'on-primary': '#D9D9D9',
      secondary: '#A8AAAE',
      'on-secondary': '#fff',
      success: '#28C76F',
      'on-success': '#fff',
      info: '#00CFE8',
      'on-info': '#fff',
      warning: '#FCD700',
      'on-warning': '#FFCE50',
      error: '#EB1C2F',
      'on-error': '#fff',
      background: '#25293C',
      'on-background': '#E4E6F4',
      surface: '#2F3349',
      'on-surface': '#E4E6F4',
      'grey-50': '#26293A',
      'grey-100': '#2F3349',
      'grey-200': '#26293A',
      'grey-300': '#4A5072',
      'grey-400': '#5E6692',
      'grey-500': '#7983BB',
      'grey-600': '#AAB3DE',
      'grey-650': '#AAB3DE',
      'grey-700': '#B6BEE3',
      'grey-800': '#CFD3EC',
      'grey-900': '#E7E9F6',
      'perfect-scrollbar-thumb': '#4A5072',
      'sys-alert-low': '#EBECF0',
      'on-sys-alert-low': '#101213',
      'sys-alert-medium': '#DAE6F5',
      'on-sys-alert-medium': '#101213',
      'sys-alert-high': '#FFE5E0',
      'on-sys-alert-high': '#101213',
      'ds-card-res-usage': '#25293C',
      'ds-pm-card-ulm': '#2F3349',
      'ds-pm-card-tuna' : '#2F3349',
      'ds-spi-dm': '#2F3349',
      'table-header-bg': '#25293C',
      'on-table-header-bg': '#E4E6F4',
      'table-header-border': '#F1F1F1',
      'btn-border': '#D9D9D9',
      'cards-border': '#D9D9D9',
      'layout-gridlines': '#D9D9D9',
      'icons-color': '#D9D9D9',
      'nav-link-active': '#454868',
      'main-primary': '#D9D9D9',
      'main-secondary': '#EB1C2F',
      'on-main-primary': '#2F3349',
      'on-main-secondary': '#fff',
      'textfield-bg': '#2E3349',
      'selects-bg': '#2E3349',
      'site-wp-card-bg': '#2F3349',
      'btn-text': '#E4E6F4',
      'ob-skin': '#323449',
      white: '#3c405a'
    },
    variables: {
      'border-color': '#CFD3EC',
      'medium-emphasis-opacity': 0.68,

      // Shadows
      'shadow-key-umbra-opacity': 'rgba(12, 16, 27, 0.15)',
      'shadow-key-penumbra-opacity': 'rgba(12, 16, 27, 0.01)',
      'shadow-key-ambient-opacity': 'rgba(12, 16, 27, 0.08)',
    },
  },
}