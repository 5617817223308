import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import { getCurrentAndParentOrigins, isUserLoggedIn } from './utils'
import routes from '~pages'
import { useRootStore } from '@/stores/root'
import { useUserStore } from '@/stores/user'
import {
  getWhiteLabelPortalName,
  isBionicSite,
} from '@/utils/whiteLabelHelpers'
import { signInWithCustomToken } from 'firebase/auth'
import { auth } from '@/firebase'
import { useTitle } from '@vueuse/core'
import { User } from '@/models/user'
import { getUser } from '@/services/userService'
import { transformToUserProfileModel } from '@/utils/objectTransformers/user'
import { getLocalStorageItem } from '@/utils/storageHelpers'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [...setupLayouts(routes)],
  scrollBehavior() {
    return { top: 0 }
  },
})

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach(async (to, from) => {
  // const origins = getCurrentAndParentOrigins({ domainOnly: true })
  let currentOrigin = window.location.origin
  currentOrigin = currentOrigin?.replace(/^https?:\/\//, '')
  // const title = getWhiteLabelPortalName() || document.title
  // useTitle(`${title}${to.meta?.title ? ` | ${to.meta?.title}` : ''}`, {})
  const rootStore = useRootStore()
  rootStore.showFullscreenLoader = true
  const userStore = useUserStore()
  if (to.query.visiting) {
    localStorage.setItem('customer-uuid', <string>to.query.visiting)
    userStore.visitedUID = String(to.query.visiting)
    // return {
    //     name: 'index',
    //     query: {},
    // }
  } else if (localStorage.getItem('customer-uuid')) {
    userStore.visitedUID = localStorage.getItem('customer-uuid')
  }
  const isLoggedIn: any = await isUserLoggedIn()
  if (to.query.loginToken) {
    try {
      await signInWithCustomToken(auth, to.query?.loginToken as string)
      await getUser()
        .then(async (userProfile: User) => {
          userStore.setUserProfile(transformToUserProfileModel(userProfile))
        })
        .catch(() => {
          userStore.logout()
        })
      return '/sites'
    } catch (e) {
      //console.log(e)
    }
  }
  if (to.meta.subject === 'Auth') {
    if (isLoggedIn) {
      return '/'
    }
  } else if (to.meta.subject === 'Guest') {
    return true
  } else {
    if (to.path === '/sites/add' && userStore.userHasUnpaidInvoice) {
      rootStore.showSnackbar(
        'You have an unpaid invoice. Please pay it first to create a new site.',
        'error',
      )
      return '/'
    }

    if (
      (to.path === '/affiliate' ||
        to.path === '/white-label' ||
        (userStore.wlPlatformOfCustomer?.hide_support_form &&
          to.path === '/support')) &&
      userStore.isWLCustomer &&
      !isBionicSite()
    ) {
      return '/'
    }

    if (!isLoggedIn) {
      return {
        name: 'login',
        query: {
          // eslint-disable-next-line no-mixed-spaces-and-tabs
          to:
            to.name !== 'index'
              ? to.fullPath.replace('visiting', 'user')
              : undefined,
        },
      }
    } else if (
      userStore.isWLOwnerAndLoggedInAsCustomer &&
      to.name !== 'sites-site_id'
    ) {
      localStorage.removeItem('customer-uuid')
      localStorage.removeItem('wl-owner-visiting-customer')
      userStore.visitedUID = null
      userStore.wlVisitingCustomer = false
      try {
        const user = await userStore.updateUser()
        if (user) {
          userStore.replaceAdminWithUser(user)
          userStore.wlPlatformOfCustomer = user.wlPlatformOfCustomer
          userStore.wlDetails = user.wlDetails
        }
      } catch (e) {
        //
      }
      rootStore.showSnackbar(
        'This path is not allowed for the current user. Redirecting to the white-label dashboard.',
      )
      return '/white-label'
    } else if (
      to.meta.subject !== 'Admin' &&
      !to.fullPath.includes('admin') &&
      isLoggedIn?.accountType === 'admin'
    ) {
      return {
        name: 'admin',
      }
    } else if (
      isLoggedIn?.accountType === 'user' &&
      to.meta.subject === 'Admin'
    ) {
      return {
        name: 'index',
      }
    }
  }
  // if (
  //   !isLoggedIn &&
  //   currentOrigin === 'my.bionicwp.com' &&
  //   to.name === 'register'
  // ) {
  //   return '/login'
  // }

  // If the route is only for different origins (i.e. register-ad page) then redirect to not found page
  // if (to.meta.differentOriginsOnly === true) {
  //   if (
  //     origins.parentOrigin &&
  //     origins.currentOrigin !== origins.parentOrigin
  //   ) {
  //     return true
  //   } else {
  //     return {
  //       name: 'all',
  //     }
  //   }
  // }
  return true
})

router.afterEach(() => {
  const rootStore = useRootStore()
  rootStore.showFullscreenLoader = false
})
export default router
