import { WhiteLabelPortalEnums } from '@/common/enums/whiteLabelPortalEnums'

export const getWhiteLabelPortalName = () => {
  return sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_NAME)
}
export const getWhiteLabelLoginHeading = () => {
  return sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_LOGIN_HEADING)
}

export const getWhiteLabelLoginSubHeading = () => {
  return sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_LOGIN_SUBHEADING)
}

export const getWhiteLabelSignupHeading = () => {
  return sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_SIGNUP_HEADING)
}

export const getWhiteLabelSignupSubHeading = () => {
  return sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_SIGNUP_SUBHEADING)
}

export const getWhiteLabelPortalLogo = () => {
  return sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_LOGO)
}
export const getWhiteLabelPortalLogoSize = () => {
  const height = sessionStorage.getItem(
    WhiteLabelPortalEnums.PORTAL_LOGO_HEIGHT,
  )
  const width = sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_LOGO_WIDTH)
  if (!height || !width) return null
  return {
    height,
    width,
  }
}

export const getWhiteLabelPortalFavicon = () => {
  return (
    sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_FAVICON) ||
    sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_LOGO)
  )
}
export const getWhiteLabelPortalPrimaryColor = () => {
  return sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_PRIMARY_COLOR)
}
export const getWhiteLabelPortalOnPrimaryColor = () => {
  return sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_ON_PRIMARY_COLOR)
}
export const getWhiteLabelSidebarLogo = () => {
  return sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_SIDEBAR_LOGO)
}
export const getWhiteLabelPortalSecondaryColor = () => {
  return sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_SECONDARY_COLOR)
}
export const getWhiteLabelPortalOnSecondaryColor = () => {
  return sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_ON_SECONDARY_COLOR)
}
export const getWhiteLabelPortalBackgroundColor = () => {
  return sessionStorage.getItem(WhiteLabelPortalEnums.PORTAL_BACKGROUND_COLOR)
}
export const getWhiteLabelPortalOnBackgroundColor = () => {
  return sessionStorage.getItem(
    WhiteLabelPortalEnums.PORTAL_ON_BACKGROUND_COLOR,
  )
}

export const isBionicSite = () => {
  const bionicPortals = [
    'my.bionicwp.com',
    'staging-customer.bionicwp.com',
    'staging-wl-front.bionicwp.com',
    'front.redesign.bionicwp.dev',
  ]
  const currentHost = sessionStorage.getItem(
    WhiteLabelPortalEnums.PORTAL_DOMAIN,
  )
  return bionicPortals.some((portal) => currentHost?.includes(portal))
}
