import axios from 'axios'
import { auth } from '@/firebase'
import ErrorCodes from '@/common/constants/errorCodes'
import { useRootStore } from '@/stores/root'

const axiosIns = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 30 * 1000,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

axiosIns.interceptors.request.use(
  async (config) => {
    const token = await auth.currentUser?.getIdToken()
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }
    const visitedUID = localStorage.getItem('customer-uuid')
    if (visitedUID && !config.headers!['customer-uuid']) {
      config.headers!['customer-uuid'] = visitedUID
    }
    config.headers!['x-wl-portal-domain'] = window.location.origin.replace(
      /^https?:\/\//,
      '',
    )
    // config.headers!['x-wl-portal-domain'] = 'my.bionicwp.com'
    // config.headers!['x-wl-portal-domain'] = 'wlagency.com'
    // config.headers!['x-wl-portal-domain'] = 'wl-test12.bwpsites.com'

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)
axiosIns.interceptors.response.use(
  (value) => {
    return Promise.resolve(value)
  },
  async (val) => {
    const rootStore = useRootStore()
    //const userStore = useUserStore()
    if (val.code === ErrorCodes.axios.ERR_NETWORK) {
      rootStore.showSnackbar(
        'Error connecting to server. Please try again.',
        'error',
      )
    } else {
      // if (val?.response?.status === 403) {
      //   await userStore.logout()
      //   if (window.location.pathname !== '/login') {
      //     window.location.reload()
      //   }
      // }
    }
    return Promise.reject(val)
  },
)

export default axiosIns
