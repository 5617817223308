import {themeConfig} from '@themeConfig'
import {
  getWhiteLabelPortalOnPrimaryColor,
  getWhiteLabelPortalOnSecondaryColor,
  getWhiteLabelPortalPrimaryColor,
  getWhiteLabelPortalSecondaryColor, isBionicSite,
} from '@/utils/whiteLabelHelpers'
import {bwpThemes} from '@/plugins/vuetify/bwpTheme'

const theme = {
  defaultTheme:
        localStorage.getItem(`${themeConfig.app.title}-theme`) ?
          isBionicSite() && !localStorage.getItem(`${themeConfig.app.title}-theme`).includes('bwp-') ? 'bwp-' + localStorage.getItem(`${themeConfig.app.title}-theme`) :
            localStorage.getItem(`${themeConfig.app.title}-theme`) :
          themeConfig.app.theme.value,
  themes: {
    myGlobalTheme: {
      dark: false,
      colors: {
        'report-attention': '#f4009a',
        'report-all-set': '#0085f0',
      },
    },
    light: {
      dark: false,
      colors: {
        primary:
getWhiteLabelPortalPrimaryColor() ||
          localStorage.getItem(
            `${themeConfig.app.title}-lightThemePrimaryColor`,
          ) ||
          '#002544',
        'on-primary': getWhiteLabelPortalOnPrimaryColor() || '#D9D9D9',
        secondary: getWhiteLabelPortalSecondaryColor() || '#A8AAAE',
        'on-secondary': getWhiteLabelPortalOnSecondaryColor() || '#fff',
        success: '#28C76F',
        'on-success': '#fff',
        info: '#00CFE8',
        'on-info': '#fff',
        warning: '#FF9F43',
        'on-warning': '#FFCE50',
        error: '#EB1C2F',
        'on-error': '#fff',
        background: '#fff',
        'on-background': '#101213',
        'on-surface': '#33303C',
        'grey-50': '#FAFAFA',
        'grey-100': '#F5F5F5',
        'grey-200': '#EEEEEE',
        'grey-300': '#E0E0E0',
        'grey-400': '#BDBDBD',
        'grey-500': '#9E9E9E',
        'grey-600': '#757575',
        'grey-650': '#858585',
        'grey-700': '#616161',
        'grey-800': '#424242',
        'grey-900': '#212121',
        'perfect-scrollbar-thumb': '#DBDADE',
        'sys-alert-low': '#EBECF0',
        'on-sys-alert-low': '#101213',
        'sys-alert-medium': '#DAE6F5',
        'on-sys-alert-medium': '#101213',
        'sys-alert-high': '#FFE5E0',
        'on-sys-alert-high': '#101213',
        'ds-card-res-usage': '#f4f4f4',
        'ds-pm-card-ulm': `rgba(${getWhiteLabelPortalSecondaryColor() || '#A8AAAE'}, 0.6)`,
        'ds-pm-card-tuna' : `rgba(${getWhiteLabelPortalPrimaryColor() || '#002544'}, 0.6)`,
        'ds-spi-dm': '#fbfbfb',
        'btn-border': '#B9B9B9',
        'cards-border': '#B6B6B6',
        'layout-gridlines': '#B6B6B6',
        'nav-link-active': '#F1F1F1',
        'icons-color': getWhiteLabelPortalPrimaryColor() ||
          localStorage.getItem(
            `${themeConfig.app.title}-lightThemePrimaryColor`,
          ) ||
          '#002544',
        'table-header-bg': '#F1F1F1',
        'on-table-header-bg': '#101213',
        'table-header-border': '#B6B6B6',
        'main-primary': getWhiteLabelPortalPrimaryColor() ||
          localStorage.getItem(
            `${themeConfig.app.title}-lightThemePrimaryColor`,
          ) ||
          '#002544',
        'main-secondary': getWhiteLabelPortalSecondaryColor() || '#A8AAAE',
        'on-main-primary': getWhiteLabelPortalOnPrimaryColor() || '#D9D9D9',
        'on-main-secondary':  getWhiteLabelPortalOnSecondaryColor() || '#fff',
        'textfield-bg': '#f1f1f1',
        'selects-bg': '#f1f1f1',
        'site-wp-card-bg': '#F1F1F1',
        white: '#2F3349',
        'ob-skin': '#F9F9F9',
        'btn-text': getWhiteLabelPortalPrimaryColor() ||
          localStorage.getItem(
            `${themeConfig.app.title}-lightThemePrimaryColor`,
          ) ||
          '#002544',
      },
      variables: {
        'border-color': '#4B465C',
        'medium-emphasis-opacity': 0.68,

        // Shadows
        'shadow-key-umbra-opacity': 'rgba(var(--v-theme-on-surface), 0.03)',
        'shadow-key-penumbra-opacity': 'rgba(var(--v-theme-on-surface), 0.02)',
        'shadow-key-ambient-opacity': 'rgba(var(--v-theme-on-surface), 0.01)',
        'textfields-bg': 'rgba(var(--v-theme-textfield-bg), 0.4)',
      },
    },
    dark: {
      dark: true,
      colors: {
        primary:
                    localStorage.getItem(
                      `${themeConfig.app.title}-darkThemePrimaryColor`,
                    ) || '#D9D9D9',
        'on-primary': '#002544',
        secondary: '#A8AAAE',
        'on-secondary': '#000',
        success: '#28C76F',
        'on-success': '#fff',
        info: '#00CFE8',
        'on-info': '#fff',
        warning: '#FF9F43',
        'on-warning': '#fff',
        error: '#EB1C2F',
        background: '#25293C',
        'on-background': '#E4E6F4',
        surface: '#2F3349',
        'on-surface': '#E4E6F4',
        'grey-50': '#26293A',
        'grey-100': '#2F3349',
        'grey-200': '#26293A',
        'grey-300': '#4A5072',
        'grey-400': '#5E6692',
        'grey-500': '#7983BB',
        'grey-600': '#AAB3DE',
        'grey-700': '#B6BEE3',
        'grey-800': '#CFD3EC',
        'grey-900': '#E7E9F6',
        'perfect-scrollbar-thumb': '#4A5072',
        'sys-alert-low': '#D9D9D9',
        'on-sys-alert-low': '#002544',
        'sys-alert-medium': '#002544',
        'on-sys-alert-medium': '#D9D9D9',
        'sys-alert-high': '#EB1C2F',
        'on-sys-alert-high': '#fff',
        'ds-card-res-usage': '#2F3349',
        'ds-pm-card-ulm': '#2F3349',
        'ds-pm-card-tuna': '#2F3349',
        'ds-spi-dm': '#2F3349',
        'ob-skin': '#323449',
        white: '#2F3349'
      },
      variables: {
        'border-color': '#CFD3EC',
        'high-emphasis-opacity': 0.87,
        'medium-emphasis-opacity': 0.68,
        'low-emphasis-opacity': 0.1,

        // Shadows
        'shadow-key-umbra-opacity': 'rgba(12, 16, 27, 0.15)',
        'shadow-key-penumbra-opacity': 'rgba(12, 16, 27, 0.01)',
        'shadow-key-ambient-opacity': 'rgba(12, 16, 27, 0.08)',
      },
    },
    'bwp-light': bwpThemes.light,
    'bwp-dark': bwpThemes.dark,
  },
}

export default theme
